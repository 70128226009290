.cart-page__wrapper {
  margin-top: calc($header-height-mobile + 32px);
  margin-bottom: 200px;

  .cart-list-item-loading {
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 10px);
    font-size: 20px;
    text-align: center;
    z-index: 1;
  }

  .product-list__col-names {
    margin: 24px 0 8px;
    > div:last-child {
      text-align: right;
    }

    .col-name__title {
      padding-left: calc(var(--bs-gutter-x) * 0.25);
    }
  }

  .product-list .row {
    padding-top: 16px;
  }

  .centered-cols {
    justify-content: center;
    flex-direction: column;

    .base-price {
      color: $primary-midtone;
      font-size: 14px;
      text-decoration: line-through;
    }

    &:last-child {
      align-items: flex-end;
    }
  }
  .cart-item-btn {
    background: none;
    border: none;
    cursor: pointer;

    &:hover,
    &:focus {
      color: $shade-white;
    }
  }
  .cart-item-delete-btn {
    text-transform: capitalize;
    i {
      margin-right: 0px !important;
    }
  }
  .small-screen-bottom {
    justify-content: space-between;
    align-items: center;
    .base-price {
      color: $primary-midtone;
      font-size: 14px;
      text-decoration: line-through;
    }
    .quantity {
      width: 40%;
      text-align: center;
    }
  }

  .dark-row {
    background-color: $shade-7;
  }

  .remove-cart-pending {
    filter: blur(8px);
    -webkit-filter: blur(8px);
  }

  .cart-item-description > span {
    display: inline-block;
    margin-bottom: 8px;
  }

  .cart-bottom__wrapper {
    .sticky-cart {
      position: fixed;
      bottom: 0;
      background-color: $shade-6;
      border-top: $border-width solid $shade-4;
      z-index: 10;
      left: 0;
    }
    .cart-total-rows-wrapper {
      & > div {
        align-items: center;
        justify-content: space-between;
      }
    }
    .price-label {
      float: right;
    }
    .discount {
      color: $primary-midtone;
    }
    .checkout-btn {
      width: 100%;
    }

    button {
      text-transform: capitalize;
    }

    .cart__add-ons-list .cart__add-ons-item {
      .cart__add-ons-btn {
        padding: 8px 32px;
      }

      .cart__add-ons-logo {
        position: relative;
        padding-bottom: 56.2%;

        img {
          position: absolute;
          object-fit: cover;
          width: 100%;
          height: 100%;
          border-radius: 5px;
        }
      }
    }
  }

  .line {
    background: #e3e1e5;
    height: 1px;
  }

  .cart-empty__wrapper {
    .notification {
      max-width: 600px;
      margin: 0 auto;
    }
    .cart-empty__content {
      margin-top: 44px;
      span {
        display: block;
      }
      .btn-browse-streams {
        width: 256px;
      }

      .oa-cart {
        font-size: 146px;
      }
    }
  }

  .quantity-dropdown {
    min-width: 50px;
  }

  .event-logo-and-desc {
    .cart-event-logo {
      padding-right: 12px;
      img {
        border-radius: 10px;
        object-fit: cover;
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .cart-page__wrapper {
    .small-screen-bottom {
      .base-price {
        font-size: 14px;
      }
    }
    .cart-bottom__wrapper {
      .cart-total-rows-wrapper {
        border-top: none;
        position: relative;
        bottom: auto;
        background-color: transparent;
        z-index: unset;
      }
    }
    .landscape-price {
      font-weight: 700;
    }
    .landscape-total-text {
      color: $shade-3;
    }
  }
}

@include media-breakpoint-up(md) {
  .cart-page__wrapper {
    margin-top: calc($header-height + 64px);
    .product-list__col-names {
      margin-top: 32px;
    }
    .cart-bottom__wrapper {
      padding-left: 0px;
      > div {
        padding: 0 12px;
      }
    }
    .product-list .row {
      padding-top: 24px;
    }
    .cart-item-title {
      font-size: 16px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .cart-page__wrapper {
    .cart-event-logo {
      img {
        width: 180px;
        height: 104px;
      }
    }
    .cart__product-image {
      width: 160px;
      height: 90px;
    }
  }
}
